<template>
    <div class="pop" v-if="showDelete">
        <p>{{ $t('delete_link_confirmation_popup') }}</p>
        <div class="btn-delete">
            <button @click="closeDelete">{{ $t('cancel') }}</button>
            <button class="link-delete" @click="callDelete">{{ $t('confirm') }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeleteLinkPanel',
    props: {
        showDelete: Boolean,
    },
    methods: {
        closeDelete() {
            this.$emit('close-delete'); // Emit a custom event to close the popup
        },
        callDelete() {
            this.$emit('call-delete');
        },
    },
}
</script>

<style lang="scss" scoped>
.pop {
    background-color: #F9F9F9;
    padding: 2em;
    border-radius: .5em;
    width: 80%;
    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.3em;
        font-weight: bold;
    }
    .btn-delete {
        display: flex;
        justify-content: space-between;
        gap: 2em;
        button {
            width: 100%;
            padding: .5em 0;
            border: none;
            outline: none;
            border-radius: .5em;
            text-transform: capitalize;
        }
        .link-delete {
            background-color: #D83F31;
            color: white;
        }
    }
}
</style>